// 产品服务
import request from '@/util/request.js'

// 产品服务列表
export function ProductList() {
	return request({
		method: 'get',
		url: `/api/product/list`
	})
}
