<template>
	<div class="chanPinFuWu" id="chanPinFuWu">
		<div class="page-content">
			<h-img class="pic-001" :src="$store.getters.getMenuItem('chanPinFuWu').frontImg"></h-img>
			<div class="main-content">
				<div class="module-1" v-for="(item,index) in chanPinFuWu" :key="index">
					<div :id="item.productTitle" :style="isMobile ? 'width: 100%;height: 80px;':'width: 100%;height: 100px;'"></div>
					<div class="theme">{{item.productTitle}}—{{item.productSubtitle}}</div>
					<div class="content-box">
						<div class="content wow animate__animated " :class="[isMobile ? (index%2==0 ? 'animate__fadeIn':'animate__fadeIn') : (index%2==0 ? 'animate__fadeInLeft':'animate__fadeInRight')]" style="object-fit: contain;" :style="{order: index%2==0 ? 1:2}">
							<div class="title">{{item.productSubtitle}}</div>
							<div class="content-txt">{{item.productDescription}}</div>
							<div class="links">
								<div class="links-txt" >{{item.productKeyword.replace(/,/g,' / ')}}</div>
							</div>
						</div>
						<h-img class="pic-010 wow animate__animated " :class="[isMobile ? (index%2==1 ? 'animate__fadeIn':'animate__fadeIn') : (index%2==1 ? 'animate__fadeInLeft':'animate__fadeInRight')]" style="object-fit: contain;" :style="{order: index%2==1 ? 1:2}" :src="item.productImg"></h-img>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {ProductList} from '@/apis/chanPinFuWu.js'
	export default {
		data() {
			return {
				chanPinFuWu: [],

			}
		},
		created() {
			this.getProductList()
		},
    mounted() {

    },
    methods: {
			getProductList() {
				ProductList().then(res => {
					this.chanPinFuWu = res.data
					
					
					
					this.$nextTick(() => {
						
						new WOW({
						  boxClass: 'wow',
						  animateClass: 'animated',
						  offset: this.isMobile ? 0:150,
						  mobile: true,
						  live: false
						}).init();
						
						if (this.erJiMenu_path) {
							let find_item = this.chanPinFuWu.find(val => val.productTitle == this.erJiMenu_path)
							if (find_item) {
								document.querySelector(`#${find_item.productTitle}`).scrollIntoView({
									behavior: 'smooth'
								})
							}
						}

					})
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import './chanPinFuWu.scss';
	@import './chanPinFuWuMedia.scss';
</style>
