import { render, staticRenderFns } from "./chanPinFuWu.vue?vue&type=template&id=7beb7636&scoped=true"
import script from "./chanPinFuWu.vue?vue&type=script&lang=js"
export * from "./chanPinFuWu.vue?vue&type=script&lang=js"
import style0 from "./chanPinFuWu.vue?vue&type=style&index=0&id=7beb7636&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7beb7636",
  null
  
)

export default component.exports